import styled, { css, RuleSet } from 'styled-components';
import { Typography } from '../Typography';
import { FlexibleDiv } from '../../../styled/common';

export const ChipsContainer = styled(FlexibleDiv)<{
  isActive: boolean;
  styles?: RuleSet<object>;
}>`
  align-items: center;
  border-radius: 100%;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.systemColors.blue
      : theme.colors.background.tertiary};

  ${({ styles }) => styles}
  transition: background-color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      ${({ isActive }) =>
        !isActive &&
        css`
          span {
            color: ${({ theme }) => theme.colors.systemColors.blue};
          }
        `}
    }
  }
`;

export const ChipsText = styled(Typography)<{ $isActive: boolean }>`
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.background.primary : theme.colors.text.primary};
  transition: color 0.3s ease;
`;
