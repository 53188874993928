import React, { useEffect, useRef } from 'react';
import { PopoverProps } from './types';
import { PopoverContainer } from './styled';

export const Popover = ({
  isOpen,
  children,
  closeHandler,
  width,
  horizontalAlign,
  triggerRef,
  cypress
}: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        triggerRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        closeHandler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInnerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return isOpen ? (
    <PopoverContainer
      horizontalAlign={horizontalAlign}
      ref={popoverRef}
      width={width}
      onClick={handleInnerClick}
      data-cy={`${cypress}-popover`}
    >
      {children}
    </PopoverContainer>
  ) : null;
};
