import styled, { css } from 'styled-components';
import { PopoverProps } from './types';

export const PopoverContainer = styled.div<
  Pick<PopoverProps, 'width' | 'horizontalAlign'>
>`
  position: absolute;
  z-index: 10;
  width: ${({ width }) => `${width}px` || 'max-content'};
  height: max-content;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 14px;
  padding: 15px;
  ${({ horizontalAlign }) =>
    horizontalAlign ||
    css`
      left: 0;
    `}
`;
