import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { RuleSet } from 'styled-components';
import { getDataForTransformationsQuery } from '../../../../../queries';
import { NewRadioButton } from '../../../../NewRadioButton';
import { setSearchSort } from '../../../../../actions';
import { selectSearchFormCurrentSortType } from '../../../../../store/selectors';
import { SORTING_VARIANTS } from '../../../../../utils/helpers/constants';
import { NewSortingContainer } from './styled';
import { generateSortingHrefAndAs } from '../../../../../utils/helpers/generateSortingHrefAndAs';

interface NewSortingProps {
  hide: () => void;
  styles?: RuleSet<object>;
}

export const NewSorting: React.FC<NewSortingProps> = ({ hide, styles }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const currentSort = useSelector(selectSearchFormCurrentSortType);
  const { data } = useQuery(getDataForTransformationsQuery, {
    fetchPolicy: 'cache-only'
  });

  const onChangeRadioHandler = (event?: ChangeEvent<HTMLInputElement>) => {
    if (!event) return;

    const { search, ...queryObj } = router.query;

    const { href, as } = generateSortingHrefAndAs({
      queryObj,
      search,
      sortValue: event.target.value,
      sortData: data.sort,
      pathname: router.pathname
    });

    dispatch(setSearchSort(event.target.value));
    router.push(href, as);
    hide();
  };

  return (
    <NewSortingContainer styles={styles}>
      {SORTING_VARIANTS.map(sorting => (
        <NewRadioButton
          data={{ ...sorting, name: t(sorting.name) }}
          key={sorting.id}
          cypress="sorting"
          stat={sorting.stat}
          name="sorting"
          currentValue={currentSort || '1'}
          onChangeHandler={onChangeRadioHandler}
          containerClassNames="filter-modal__radio"
        />
      ))}
    </NewSortingContainer>
  );
};
