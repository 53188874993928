import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { ModalFooter } from '../../../../ui/Modal/ModalFooter';
import { actionButtonStyles, RangeFilterWrapper } from '../../styled';
import { RangeFilter } from '../../../../ui/RangeFilter';

interface NewAreaProps {
  hide: () => void;
}

export const NewArea = ({ hide }: NewAreaProps) => {
  const { t } = useTranslation();
  const { values } = useFormState();
  const { change, submit } = useForm();
  const aresFrom = values.area_from;
  const areaTo = values.area_to;
  const resetButtonIsDisabled = !aresFrom && !areaTo;

  const onReset = () => {
    change('area_from', '');
    change('area_to', '');
  };

  const onSubmit = () => {
    submit();
    hide();
  };

  return (
    <>
      <RangeFilterWrapper>
        <RangeFilter
          minId="new-search-form-area-from"
          minName="area_from"
          maxName="area_to"
          maxId="new-search-form-area-to"
        />
      </RangeFilterWrapper>
      <ModalFooter
        resetIsDisabled={resetButtonIsDisabled}
        onReset={onReset}
        onConfirm={onSubmit}
        resetText={t('common.reset')}
        confirmText={t('common.apply')}
        confirmCypress="apply-btn"
        resetCypress="reset-btn"
        styles={actionButtonStyles}
      />
    </>
  );
};
