import { ModalParams } from '../types';

export enum ModalConfig {
  Sorting = 'sorting',
  Price = 'price',
  Area = 'area',
  RoomsCount = 'roomsCount',
  BuildingType = 'buildingType',
  LeasedType = 'leasedType'
}

export interface SearchPageFiltersProps {
  setModalParams: React.Dispatch<React.SetStateAction<ModalParams>>;
}
