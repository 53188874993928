import styled, { css } from 'styled-components';
import { ellipsisText, FlexCenterAligned } from '../../../styled/common';
import { Typography } from '../Typography';
import { PopoverContainerProps, PopoverHorizontalAlignmentEnum } from './types';
import { StyledDeleteIcon } from '../Input/styled';

export const popoverHorizontalAlignment = (
  horizontalAlign: PopoverHorizontalAlignmentEnum
) => css`
  top: calc(100% + 6px);
  ${horizontalAlign === PopoverHorizontalAlignmentEnum.left &&
  css`
    left: 0;
  `};
  ${horizontalAlign === PopoverHorizontalAlignmentEnum.right &&
  css`
    right: -1px;
  `};
`;

const selectedStyles = css`
  path:not(${StyledDeleteIcon} path) {
    fill: ${({ theme }) => theme.colors.systemColors.blue};
    color: ${({ theme }) => theme.colors.systemColors.blue};
    stroke: ${({ theme }) => theme.colors.systemColors.blue};
  }

  > span:nth-child(1) {
    color: ${({ theme }) => theme.colors.systemColors.blue};
  }
`;

export const PopoverFilterContainer = styled.button<PopoverContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  gap: 5px;
  height: 54px;
  width: max-content;
  justify-content: space-between;
  box-sizing: border-box;

  path {
    fill: ${({ theme, hasValue }) =>
      hasValue ? theme.colors.text.primary : theme.colors.text.secondary};
  }

  &:hover {
    ${selectedStyles}
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      ${selectedStyles}
    `}

  ${({ styles }) => styles};
`;

export const PopoverText = styled(Typography)`
  color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.colors.text.primary : theme.colors.text.secondary};
  text-align: left;
  ${ellipsisText};
`;

export const ArrowIcon = styled(FlexCenterAligned)<PopoverContainerProps>`
  width: 24px;
  height: 24px;
  transform: rotate(90deg);

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      transform: rotate(270deg);
    `}
`;
