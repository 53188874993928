import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { NewRadioButton } from '../../../../NewRadioButton';
import { LEASED_TYPES } from '../../../../../utils/helpers/constants';

interface NewLeasedProps {
  hide: () => void;
}

const LeasedContainer = styled.div`
  label {
    cursor: pointer;
    height: 54px;
    border-radius: 14px;
    border: 1px solid ${({ theme }) => theme.colors.border.primary};

    &::after {
      display: none;
    }
  }

  label:nth-child(1) {
    margin-bottom: 15px;
  }
`;

export const NewLeased = ({ hide }: NewLeasedProps) => {
  const { t } = useTranslation();
  const { values } = useFormState();
  const { change, submit } = useForm();

  const onClick = (value: string) => {
    change('leased', value);
    submit();
    hide();
  };

  return (
    <LeasedContainer>
      {LEASED_TYPES.map(type => (
        <NewRadioButton
          key={type.id}
          data={{
            id: type.value,
            name: t(type.name)
          }}
          currentValue={values?.leased}
          onChangeHandler={() => onClick(type.value)}
          name={type.name}
          cypress={type.id}
          stat={type.id}
        />
      ))}
    </LeasedContainer>
  );
};
