import styled, { css } from 'styled-components';

export const modalStyles = css`
  border-radius: 7px 7px 0 0;
  padding-bottom: env(safe-area-inset-bottom);
  height: max-content;
  align-self: end;
`;

export const ModalContent = styled.div`
  padding: 15px;
  padding-bottom: 25px;
`;

export const PriceTitleContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;
