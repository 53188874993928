import { getValueFromObject, toQueryString } from './common';

interface GenerateSortingHrefAndAsParams {
  search: string[] | string | undefined;
  queryObj: { [key: string]: string | undefined | string[] };
  sortValue: string;
  sortData: { id: string; params: string; name: string }[];
  pathname: string;
}

export const generateSortingHrefAndAs = ({
  search = [],
  queryObj,
  sortValue,
  sortData,
  pathname
}: GenerateSortingHrefAndAsParams) => {
  const { url, ...newQueryObj } = queryObj;

  const generateQueryString = (concatSign = '?') => {
    const updatedQueryObj = {
      ...newQueryObj,
      sorting: getValueFromObject(sortData, sortValue, 'params')
    };

    return Object.keys(updatedQueryObj).length
      ? `${concatSign}${toQueryString(updatedQueryObj)}`
      : '';
  };
  const searchArr = Array.isArray(search) ? search : [];

  const resolvedBasePath = search.length
    ? searchArr.join('/')
    : pathname.replace('/', '');

  const cleanPath = (path: string) =>
    path.replace(/\/\//g, '/').replace(/([&?])$/, '');

  const href = cleanPath(
    `/${pathname}?url=${resolvedBasePath}${generateQueryString('&')}`
  );
  const as = cleanPath(`/${resolvedBasePath}${generateQueryString()}`);

  return { href, as };
};
