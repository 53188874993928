import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLockBodyScroll } from '../../../../../hooks/useLockBodyScroll';
import { FilterModalProps } from './types';
import { ModalContent, modalStyles, PriceTitleContainer } from './styled';
import { NewBuildingType } from '../NewBuildingType';
import useScreenSize from '../../../../../hooks/useScreenSize';
import { NewSorting } from '../NewSorting';
import { NewPrice } from '../NewPrice';
import { NewArea } from '../NewArea';
import { RoomsCount } from '../RoomsCount';
import { NewLeased } from '../NewLeased';
import { Modal } from '../../../../ui/Modal';
import { CurrencySymbol } from '../../../../ui/ItemCard/CurrencySymbol';
import { BUILDING_TYPE_ID } from '../../../../../utils/helpers/constants';

const priceUnits = <CurrencySymbol currency="AZN" />;

export const FilterModal = ({
  hide,
  isOpen,
  modalConfig,
  values
}: FilterModalProps) => {
  const isSortModal = modalConfig === 'sorting';
  useLockBodyScroll(isOpen, isSortModal);
  const { isSmall } = useScreenSize();
  const { t } = useTranslation();

  const areaUnits =
    values?.category_id === BUILDING_TYPE_ID.AREA
      ? t('search.form.units.acres')
      : t('search.form.units.metres');

  const areaTitle = `${t('search.form.area')} ${areaUnits}`;

  const priceTitle = (
    <PriceTitleContainer>
      {`${t('new_search.price')}, `}
      {priceUnits}
    </PriceTitleContainer>
  );

  useEffect(() => {
    if (!isSmall) {
      hide();
    }
  }, [isSmall]);

  if (!isOpen || !modalConfig) return null;

  const filterConfig = {
    sorting: { title: 'search.buttons.sort', Component: NewSorting },
    price: { title: priceTitle, Component: NewPrice },
    area: { title: areaTitle, Component: NewArea },
    roomsCount: { title: 'search.form.rooms_number', Component: RoomsCount },
    buildingType: {
      title: 'new_search.type_estate',
      Component: NewBuildingType
    },
    leasedType: {
      title: 'search.filters.dealType',
      Component: NewLeased
    }
  };

  const content = filterConfig[modalConfig];
  const { title, Component } = content;
  const modalTitle = typeof title === 'string' ? t(title) : title;

  return (
    <Modal
      isOpen={isOpen}
      onClose={hide}
      modalId="filter-modal"
      cypress="filter-modal"
      modalStyles={modalStyles}
      title={modalTitle}
      closeButtonAlign="right"
    >
      <ModalContent>
        <Component hide={hide} />
      </ModalContent>
    </Modal>
  );
};
