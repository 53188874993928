import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { useEffect, useState } from 'react';
import { Input, InputTypeMap } from '../Input';
import { RangeFilterContainer, Separator } from './styled';

interface RangeFilterProps {
  minName: string;
  minId: string;
  maxName: string;
  maxId: string;
  rangeInFilterModal?: boolean;
}

export const RangeFilter = ({
  minName,
  minId,
  maxId,
  maxName,
  rangeInFilterModal = false
}: RangeFilterProps) => {
  const { t } = useTranslation();
  const { values } = useFormState();
  const { change } = useForm();

  const [minValue, setMinValue] = useState(values[minName]);
  const [maxValue, setMaxValue] = useState(values[maxName]);

  useEffect(() => {
    if (rangeInFilterModal) setMinValue(values[minName]);
  }, [values[minName]]);

  useEffect(() => {
    if (rangeInFilterModal) setMaxValue(values[maxName]);
  }, [values[maxName]]);

  const onBlurMinHandler = () => {
    change(minName, minValue);

    const isFirstFloor = minName === 'floor_from' && minValue === '1';
    const isSameMinMaxFloor =
      minName === 'floor_from' &&
      maxName === 'floor_to' &&
      minValue === maxValue &&
      values.floor_last === 'false';

    if (isFirstFloor) {
      change('floor_first', false);
    }

    if (isSameMinMaxFloor) {
      change('floor_last', undefined);
    }
  };

  return (
    <RangeFilterContainer>
      <Input
        name={minName}
        type={InputTypeMap.range}
        id={minId}
        stat={minId}
        placeholder={t('search.form.from')}
        {...(rangeInFilterModal && {
          value: minValue,
          onChange: (value: string) => {
            setMinValue(value);
          },
          onBlurHandler: onBlurMinHandler,
          isCustomInput: true
        })}
      />
      <Separator />
      <Input
        name={maxName}
        type={InputTypeMap.range}
        id={maxId}
        stat={maxId}
        placeholder={t('search.form.to')}
        {...(rangeInFilterModal && {
          value: maxValue,
          onChange: (value: string) => setMaxValue(value),
          onBlurHandler: () => change(maxName, maxValue),
          isCustomInput: true
        })}
      />
    </RangeFilterContainer>
  );
};
