import styled, { RuleSet } from 'styled-components';

export const NewSortingContainer = styled.div<{ styles?: RuleSet<object> }>`
  label {
    cursor: pointer;
    padding: 0;
    height: 54px;

    &::after {
      display: none;
    }

    > span {
      font-size: 15px;
    }
  }

  ${({ styles }) => styles};
`;
