import { ReactNode } from 'react';
import { RuleSet } from 'styled-components';

export enum PopoverHorizontalAlignmentEnum {
  left = 'left',
  right = 'right'
}
export interface PopoverFilterProps {
  value?: string | ReactNode;
  placeholder: string | ReactNode;
  children: ReactNode;
  cypress: string;
  width: number;
  stat?: string;
  horizontalAlign?: PopoverHorizontalAlignmentEnum;
  showIcon?: boolean;
  styles?: RuleSet<object>;
  onClick?: () => void;
  onClose?: () => void;
}

export interface PopoverContainerProps {
  isSelected: boolean;
  hasValue?: boolean;
  styles?: RuleSet<object>;
}
