import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react';
import { PopoverFilterProps, PopoverHorizontalAlignmentEnum } from './types';
import { Popover } from '../Popover';
import {
  ArrowIcon,
  PopoverFilterContainer,
  popoverHorizontalAlignment,
  PopoverText
} from './styled';
import Icon from '../../Icon';
import ArrowRight from '../../../public/static/images/item/arrow-right.svg';
import useScreenSize from '../../../hooks/useScreenSize';

export const PopoverFilter = forwardRef(
  (
    {
      children,
      value,
      placeholder,
      cypress,
      width,
      stat,
      horizontalAlign = PopoverHorizontalAlignmentEnum.left,
      showIcon = true,
      styles,
      onClick,
      onClose,
      isOpenedFilter
    }: PopoverFilterProps,
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const triggerRef = useRef<HTMLButtonElement | null>(null);
    const { isSmall } = useScreenSize();

    const handleFilterClick = () => {
      if (onClick) onClick();
      setIsPopoverOpen(!isPopoverOpen);
      if (isPopoverOpen) onClose?.();
    };

    const handleClose = () => {
      setIsPopoverOpen(false);
      onClose?.();
    };

    useImperativeHandle(ref, () => ({
      closePopover: handleClose
    }));

    const isSelected =
      isSmall && isOpenedFilter !== undefined ? isOpenedFilter : isPopoverOpen;

    return (
      <PopoverFilterContainer
        ref={triggerRef}
        $isSelected={isSelected}
        type="button"
        onClick={handleFilterClick}
        data-cy={cypress}
        hasValue={!!value}
        data-stat={stat}
        styles={styles}
      >
        <PopoverText $hasValue={!!value} tag="body_2">
          {value || placeholder}
        </PopoverText>
        {showIcon && (
          <ArrowIcon $isSelected={isSelected}>
            <Icon
              indent={false}
              height={14}
              width={7}
              IconComponent={ArrowRight}
            />
          </ArrowIcon>
        )}
        {!isSmall && (
          <Popover
            triggerRef={triggerRef}
            width={width}
            horizontalAlign={popoverHorizontalAlignment(horizontalAlign)}
            isOpen={isPopoverOpen}
            closeHandler={handleClose}
            cypress={cypress}
          >
            {children}
          </Popover>
        )}
      </PopoverFilterContainer>
    );
  }
);

PopoverFilter.displayName = 'PopoverFilter';
