import styled, { css } from 'styled-components';
import { FlexibleDiv } from '../../../../../styled/common';

export const RoomsContainer = styled(FlexibleDiv)`
  gap: 10px;
  flex-wrap: no-wrap;
  padding-bottom: 30px;
`;

export const chipsStyles = css`
  width: 100%;
  height: 44px;
  justify-content: center;
  border-radius: 1000px;
`;
