import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { NewSearchCategories } from '../../../NewSearchCategories';

interface NewBuildingTypeProps {
  hide: () => void;
}

const CategoriesContainer = styled.div`
  > div {
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
  }
`;

export const NewBuildingType = ({ hide }: NewBuildingTypeProps) => {
  const { values } = useFormState();
  const { change, submit } = useForm();

  const onClick = (value: string) => {
    change('category_id', value);
    submit();
    hide();
  };

  return (
    <CategoriesContainer>
      <NewSearchCategories
        onClick={onClick}
        selectedValue={values.category_id}
      />
    </CategoriesContainer>
  );
};
