import { ChipsContainer, ChipsText } from './styled';
import { ChipsProps } from './types';

export const Chips = ({
  text,
  type = 'single',
  value,
  storedValue,
  onClick,
  styles,
  cypress,
  stat
}: ChipsProps) => {
  const normalizedStoredValue =
    storedValue === 'undefined' ? undefined : storedValue;
  const isActive =
    type === 'single'
      ? value === normalizedStoredValue
      : typeof value === 'string' && normalizedStoredValue?.includes(value);

  return (
    <ChipsContainer
      styles={styles}
      isActive={!!isActive}
      onClick={onClick}
      role="button"
      data-cy={cypress}
      data-stat={stat}
    >
      <ChipsText $isActive={!!isActive} tag="body_1">
        {text}
      </ChipsText>
    </ChipsContainer>
  );
};
